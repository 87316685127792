<template>
  <AppExpansionItem
    ref="expansion"
    class="form-field"
    :disable="!isReleased"
    :label="params.label"
  >
    <template #header>
      <AppButton
        v-if="!isReleased"
        :label="$t('button.comingSoon')"
        size="14px"
        padding="8px 21px"
        disabled
      />
      <AppButton
        v-else-if="!hasReminders"
        :label="$t('button.buy')"
        class="btn-buy"
        size="14px"
        color="white"
        text-color="primary"
        padding="8px 21px"
        @click="buy"
      />
    </template>
    <div
      v-if="hasReminders && isReleased"
      class="q-pt-sm q-px-lg q-pb-lg"
    >
      <div class="bg-grey-6 border-radius-14 q-pa-lg q-mb-lg">
        <div class="flex justify-between q-mb-sm">
          <div
            v-t="params.usedNotifications"
            class="text-grey"
          />
          <div>{{ remindersInfo.sent }} / {{ remindersInfo.limit }}</div>
        </div>
        <q-slider
          track-size="1px"
          thumb-path=""
          class="q-mb-md"
          :value="remindersInfo.sent"
          :min="0"
          :max="remindersInfo.limit"
          dense
          readonly
        />
        <div class="flex justify-between">
          <div
            v-t="params.sentNotifications"
            class="text-grey"
          />
          <div>{{ remindersInfo.sent }}</div>
        </div>
      </div>

      <div
        v-t="params.typeNotification"
        class="text-grey"
      />
      <div class="form-builder__option form-builder__option_horizontal">
        <AppToggle
          v-model="confirmationEnabled"
          :label="$t('label.sendWebinarConfirmation')"
          left-label
        />
      </div>
      <div class="form-builder__option form-builder__option_horizontal">
        <AppToggle
          v-model="reminderEnabled"
          :label="$t('label.sendWebinarReminder')"
          left-label
        />
        <div
          v-if="reminderEnabled"
          class="q-gutter-y-md"
        >
          <TimeSelector
            v-for="(time, index) in reminderIntervals"
            :key="index"
            :reminder-times="getTimes(time)"
            :number="index + 1"
            :value="reminderIntervals[index]"
            @input="onChangeTime(index, $event)"
            @remove="onRemoveTime(index)"
          />
          <AppButton
            v-if="reminderIntervals.length < 6"
            icon-left="fl:plus"
            icon-left-color="grey-7"
            icon-left-size="20px"
            color="grey-6"
            class="btn-add-time"
            text-color="black"
            size="14px"
            :label="$t('button.addNotification')"
            @click="addNotification"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="row text-center q-px-xl q-pb-xl justify-center relative-position"
    >
      <div class="col-8">
        <div class="q-mb-lg text-center">
          <q-img
            :src="require(`assets/mess_${type}.svg`)"
            height="74px"
            width="74px"
          />
        </div>
        <q-img
          class="arrow-img absolute"
          src="~assets/arrow_up.svg"
          height="40px"
          width="57px"
        />
        <div
          v-t="params.title"
          class="text-19-700 q-mb-md"
        />
        <div
          v-t="'text.notificationDesc'"
          class="text-15-400 text-grey-1"
        />
      </div>
    </div>
  </AppExpansionItem>
</template>

<script>
import AppExpansionItem from 'common/components//AppExpansionItem';
import AppToggle from 'common/components//AppToggle';
import AppButton from 'common/components//buttons/AppButton';
import TimeSelector from 'src/pages/dashboard/form/components/notifications/TimeSelector';
import { builderMixin } from 'src/pages/dashboard/form/mixins';
import { mapState } from 'vuex';

const HOUR = 3600;

export default {
  name: 'NotificationsItem',
  stepName: ENUMS.STEPS.NOTIFICATIONS,
  components: {
    TimeSelector,
    AppExpansionItem,
    AppToggle,
    AppButton,
  },
  mixins: [builderMixin],
  props: {
    type: {
      type: String,
      required: true,
      validate: (value) => Object.values(ENUMS.NOTIFICATION_TYPES).includes(value),
    },
    isReleased: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['buy-reminders'],
  data() {
    return {
      reminderTimes: [
        {
          label: `30 ${this.$t('label.minutes')}`,
          value: HOUR * 0.5,
        },
        {
          label: `60 ${this.$t('label.minutes')}`,
          value: HOUR,
        },
        {
          label: `90 ${this.$t('label.minutes')}`,
          value: HOUR * 1.5,
        },
        {
          label: `6 ${this.$t('label.hours')}`,
          value: HOUR * 6,
        },
        {
          label: `24 ${this.$t('label.hours')}`,
          value: HOUR * 24,
        },
        {
          label: `48 ${this.$t('label.hours')}`,
          value: HOUR * 48,
        },
      ],
    };
  },
  computed: {
    ...mapState('app', ['account']),
    remindersInfo() {
      return this.account.reminders[this.type];
    },
    hasReminders() {
      return this.remindersInfo !== false;
    },
    params() {
      const params = {
        [ENUMS.NOTIFICATION_TYPES.SMS]: {
          label: this.$t('title.sms'),
          title: this.$t('title.buySmsNotifications'),
          usedNotifications: this.$t('label.smsUsed'),
          sentNotifications: this.$t('label.smsSentByForm'),
          typeNotification: this.$t('title.smsNotification'),
        },
        [ENUMS.NOTIFICATION_TYPES.WHATS_APP]: {
          label: this.$t('title.whatsApp'),
          title: this.$t('title.buyWhatsAppNotifications'),
          usedNotifications: this.$t('label.whatsAppUsed'),
          sentNotifications: this.$t('label.notificationsSentByForm'),
          typeNotification: this.$t('title.whatsAppNotification'),
        },
        [ENUMS.NOTIFICATION_TYPES.TELEGRAM]: {
          label: this.$t('title.telegram'),
          title: this.$t('title.buyTelegramNotifications'),
          usedNotifications: this.$t('label.telegramUsed'),
          sentNotifications: this.$t('label.notificationsSentByForm'),
          typeNotification: this.$t('title.telegramNotification'),
        },
      };

      return {
        ...params[this.type],
        confirmationKey: `${this.type}ConfirmationEnabled`,
        isActiveKey: `${this.type}ReminderEnabled`,
        intervalKey: `${this.type}ReminderIntervals`,
      };
    },
    confirmationEnabled: {
      get() {
        return this.formPart?.[this.params.confirmationKey];
      },
      set(value) {
        this.$set(this.formPart, this.params.confirmationKey, value);
      },
    },
    reminderEnabled: {
      get() {
        return this.formPart?.[this.params.isActiveKey];
      },
      set(value) {
        this.$set(this.formPart, this.params.isActiveKey, value);
      },
    },
    reminderIntervals: {
      get() {
        return this.formPart?.[this.params.intervalKey];
      },
      set(value) {
        this.$set(this.formPart, this.params.intervalKey, value);
      },
    },
  },
  watch: {
    reminderEnabled(value) {
      if (value) {
        if (this.reminderIntervals.length === 0) {
          this.reminderIntervals = [1800];
        }
      } else {
        this.reminderIntervals = [];
      }
    },
    reminderIntervals: {
      deep: true,
      handler() {
        if (this.reminderIntervals.length === 0) {
          this.reminderEnabled = false;
        }
      },
    },
  },
  methods: {
    buy() {
      this.$emit('buy-reminders');
      this.$refs.expansion.isOpen = true;
    },
    onChangeTime(index, time) {
      const times = [...this.reminderIntervals];
      times[index] = time;
      this.reminderIntervals = times;
    },
    onRemoveTime(index) {
      this.reminderIntervals = this.reminderIntervals.filter((value, pos) => pos !== index);
    },
    addNotification() {
      const time = this.reminderTimes.find((element) => !this.reminderIntervals.includes(element.value));
      if (!time) {
        return;
      }
      const times = [...this.reminderIntervals];
      times.push(time.value);
      this.reminderIntervals = times;
    },
    getTimes(time) {
      return this.reminderTimes
        .filter(({ value }) => !this.reminderIntervals.includes(value) || time === value);
    },
  },
};
</script>

<style scoped lang="scss">
.expansion {
  ::v-deep {
    .header {
      padding: 20px 25px;
    }

    .q-slider {
      &__track-container {
        padding: 0;
      }
      &__inner {
        background: $grey-2;
      }
    }
  }

  .btn-buy {
    ::v-deep {
      .q-btn__wrapper {
        &:before {
          border: 1px solid $primary;
        }
      }
    }
  }

  .btn-add-time {
    ::v-deep {
      .q-btn__content {
        font-weight: 400;
      }
    }
  }

  .arrow-img {
    top: 5px;
    right: 70px;
  }
}
</style>
