<template>
  <div>
    <AppCard
      class="notifications-page__panel"
      body-class="flex column"
      header-color="bg-grey-11"
      :title="$t('label.notifications')"
      bordered
    >
      <NotificationsItem
        v-for="type in Object.values(ENUMS.NOTIFICATION_TYPES)"
        :key="type"
        :type="type"
        is-released
        @buy-reminders="onBuyReminders(type)"
      />

      <TariffDialog
        v-if="showTariffDialog"
        :selected-type="selectedReminder"
        is-open
        @close="onClose"
      />
    </AppCard>

    <div class="page-hint">
      {{ $t('text.notificationPageHint') }}
    </div>
  </div>
</template>

<script>
import AppCard from 'common/components/AppCard';
import TariffDialog from 'src/pages/dashboard/form/components/form-fields/TariffDialog';
import NotificationsItem from 'src/pages/dashboard/form/components/notifications/NotificationsItem';

export default {
  name: 'NotificationsPage',
  components: {
    TariffDialog,
    NotificationsItem,
    AppCard,
  },
  data() {
    return {
      showTariffDialog: false,
      selectedReminder: undefined,
    };
  },
  methods: {
    onBuyReminders(type) {
      this.selectedReminder = type;
      this.showTariffDialog = true;
    },
    onClose() {
      this.selectedReminder = undefined;
      this.showTariffDialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .form-field {
  &:not(:last-child) {
    border-bottom: 1px solid #E3E3E3;
  }

  .body {
    background: unset;
    padding: 0;
    margin: 0;

    .q-field__control {
      background: #fff;
    }

    .form-builder__option_horizontal {
      .app-input,
      .app-select {
        grid-template-columns: 1fr 2.6fr;
      }
    }
  }
}
</style>
