<template>
  <div class="bg-grey-6 border-radius-14">
    <div class="q-px-lg q-py-md border-bottom flex justify-between items-center">
      <div class="text-grey">
        {{ $t('title.notification') }} {{ number }}
      </div>
      <q-icon
        name="fl:delete"
        class="cursor-pointer"
        color="grey-7"
        size="20px"
        @click="$emit('remove')"
      />
    </div>
    <div class="q-px-lg q-py-md flex items-center">
      <AppSelect
        v-model="smsReminderTimeBefore"
        :options="reminderTimes"
        class="col q-mr-md block"
        emit-value
        map-options
      />
      <div>{{ $t('label.beforeWebinar') }}</div>
    </div>
  </div>
</template>

<script>
import AppSelect from 'common/components//AppSelect';

export default {
  name: 'TimeSelector',
  components: { AppSelect },
  props: {
    value: {
      type: Number,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    reminderTimes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      smsReminderTimeBefore: undefined,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.smsReminderTimeBefore = this.value;
      },
    },
    smsReminderTimeBefore() {
      this.$emit('input', this.smsReminderTimeBefore);
    },
  },
};
</script>

<style scoped lang="scss">
.time-reminding {
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr !important;
  text-align: center;

  label {
    grid-column-start: 1;
  }
}
</style>
