import { render, staticRenderFns } from "./NotificationsItem.vue?vue&type=template&id=2995674e&scoped=true&"
import script from "./NotificationsItem.vue?vue&type=script&lang=js&"
export * from "./NotificationsItem.vue?vue&type=script&lang=js&"
import style0 from "./NotificationsItem.vue?vue&type=style&index=0&id=2995674e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2995674e",
  null
  
)

export default component.exports
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSlider,QImg,QBtn});
