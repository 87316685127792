<template>
  <AppModal
    :title="$t('title.selectYourTariff')"
    content-class="sms-tariff-dialog"
    position="right"
    no-maximized
    show-close-btn
    v-bind="$attrs"
    @show="onShow"
    @close="onClose"
  >
    <div class="tariff-container">
      <div class="q-pa-lg border-bottom reminder-types bg-white">
        <AppButtonToggle
          v-model="reminderType"
          :options="reminderOptions"
          class="toggle-buttons full-width"
          toggle-color="btn-toggle-selected"
          toggle-text-color="black"
          text-color="black"
          padding="11px 22px"
          spread
        />
      </div>
      <div class="q-pa-lg flex q-gutter-y-lg">
        <div
          v-for="(tariff, index) in tariffs"
          :key="`${index}-${tariff.amount}`"
          class="tariff border-radius-10 q-py-lg q-px-xl cursor-pointer full-width"
          @click="goToPayPage(tariff)"
        >
          <div class="q-gutter-x-md row">
            <div class="dialog-img relative-position">
              <q-img
                :src="tariff.thumbnail ? tariff.thumbnail.url : '~/assets/tariff_SMS100.svg'"
                width="54px"
              />
              <q-badge
                :label="tariff.amount"
                color="primary"
                text-color="black"
              />
            </div>
            <div class="dialog-info col column text-18-400">
              <div>{{ tariff.title }}</div>
              <div>{{ $t('label.price') }} – {{ tariff.price.formatted }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from 'common/components/AppModal';
import AppButtonToggle from 'common/components/buttons/AppButtonToggle';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'TariffDialog',
  components: {
    AppButtonToggle,
    AppModal,
  },
  props: {
    selectedType: {
      type: String,
      default: ENUMS.NOTIFICATION_TYPES.SMS,
      validate: (value) => Object.values(ENUMS.NOTIFICATION_TYPES).includes(value),
    },
  },
  emits: ['close'],
  data() {
    return {
      initialState: undefined,
      updateInterval: undefined,
      reminderType: undefined,
      paidType: undefined,
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters('app', ['getProducts']),
    ...mapState('app', ['account']),
    tariffs() {
      return this.getProducts(this.reminderType);
    },
    reminderOptions() {
      return [
        {
          value: ENUMS.NOTIFICATION_TYPES.SMS,
          label: this.$t('title.sms'),
        },
        {
          value: ENUMS.NOTIFICATION_TYPES.WHATS_APP,
          label: this.$t('title.whatsApp'),
        },
        {
          value: ENUMS.NOTIFICATION_TYPES.TELEGRAM,
          label: this.$t('title.telegram'),
        },
      ];
    },
    accountReminder() {
      return this.account.reminders[this.paidType];
    },
  },
  watch: {
    selectedType: {
      immediate: true,
      handler() {
        this.reminderType = this.selectedType;
      },
    },
    accountReminder: {
      deep: true,
      handler() {
        if (!this.isOpen || JSON.stringify(this.accountReminder) === this.initialState) {
          return;
        }
        this.onClose();
      },
    },
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
  methods: {
    ...mapActions('app', ['identity']),
    goToPayPage(tariff) {
      window.open(tariff.url, '_blank');
      this.paidType = this.reminderType;
      this.updateInterval = setInterval(this.identity, 3000);
    },
    onShow() {
      this.isOpen = true;
      this.initialState = JSON.stringify(this.accountReminder);
    },
    onClose() {
      this.isOpen = false;
      this.paidType = undefined;
      this.initialState = undefined;
      clearInterval(this.updateInterval);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.sms-tariff-dialog {
  .modal-header {
    background-color: $grey-6;
  }

  .modal-body {
    overflow: auto;
  }

  .tariff-container {
    max-height: calc(100vh - 96px - 60px);

    .reminder-types {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  .tariff {
    border: 1px solid $grey-2;
    transition: all 0.5s ease;

    &:hover {
      background: rgba(0, 214, 162, 0.2) !important;
      border: 1px solid $primary;

      .q-badge {
        background: #fff !important;
      }

      .dialog-info {
        & > div:first-child {
          border-bottom: 1px solid $primary;
        }
      }
    }
  }

  .dialog-img {
    .q-badge {
      position: absolute;
      top: -2px;
      left: 14px;
      font-size: 11px;
      font-weight: 700;
      padding: 5px 9px;
      transition: all 0.5s ease;
    }
  }

  .dialog-info {
    & > div {
      &:first-child {
        border-bottom: 1px solid $grey-2;
        padding-bottom: 11px;
        transition: all 0.5s ease;
      }

      &:last-child {
        padding-top: 11px;
      }
    }
  }
}
</style>
